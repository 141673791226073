















































































import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";

interface State {
	aanwezigheidslijst: Array<{
		lidNaam: string;
		lidId: number;
		deelnames: unknown;
		status: string;
	}>;
	les?: {
		groepsles_naam: string;
		status: string;
		item_type: string;
		datum: string;
		tijd: string;
		zaal_naam: string;
		bezet: number;
		plekken: number;
		trainer_naam: string;
		les_id: number;
		vestiging_naam: string;
	};
}

export default defineComponent({
	data(): State {
		return {
			aanwezigheidslijst: [],
			les: undefined,
		};
	},
	async created() {
		this.populateAanwezigheidslijst();
		this.populateLes();
	},
	async destroyed() {
		this.aanwezigheidslijst = [];
		this.les = undefined;
	},
	methods: {
		...mapActions("tijdsloten", [
			"getAanwezigheidsLijst",
			"postAanwezigheidsLijst",
			"postAanwezigheidsLijstDefinitief",
			"getLes",
		]),
		async populateLes() {
			this.les = await this.getLes(Number(this.$route.params.id));
		},
		async populateAanwezigheidslijst() {
			const data = (await this.getAanwezigheidsLijst(Number(this.$route.params.id))) as {
				lijst: State["aanwezigheidslijst"];
			};

			this.aanwezigheidslijst = data.lijst
				.map((lid) => {
					return lid;
				})
				.sort((a, b) => {
					if (a.lidNaam < b.lidNaam) return -1;
					if (a.lidNaam > b.lidNaam) return 1;
					return 0;
				})
				.sort((a, b) => {
					if (a.status === "afgemeld") return 1;
					if (b.status === "afgemeld") return -1;
					if (a.deelnames === 0) return -1;
					if (b.deelnames === 0) return 1;
					return 0;
				});
		},
		print() {
			window.print();
		},
	},
});
